import React from 'react';
import {Login, LoginForm, LoginComponent} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import bg from "../../bg.svg"

const styles = ({
    main: {
        // background: `url(${bg})`,
        backgroundPosition: 'center'
    },
    avatar: {
        height: 80,
    },
    // icon: {display: 'none'},
});

// const CustomLoginForm = withStyles({
//     button: {background: '#F15922'},
// })(LoginForm);

const CustomLoginPage: LoginComponent = (props) => (
    <Login {...props}/>
);

export default withStyles(styles)(CustomLoginPage);