import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  DataProvider,
  DateField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  EmailField,
  ReferenceManyField,
  Datagrid,
  List,
  ArrayField,
  NumberField,
  BooleanField,
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { PersonsList } from "./PersonsList/PersonsList";
import axios from "axios";
import LoginPage from "./LoginPage";
import authProvider from "./authProvider";
import {createBrowserHistory} from 'history';

export const baseUrl = process.env.REACT_APP_API_URL || "https://perepis-hvostikov.ru"
const dataProvider = jsonServerProvider(baseUrl);

const history = createBrowserHistory();



const daataProvider: DataProvider = {
  ...dataProvider,
  getList: async (resourse, params) => {
    console.log(resourse);
    const page = params.pagination.page;
    const perPage = params.pagination.perPage;
    const response = await axios.get(
      `${baseUrl}/${resourse}?page=${page}&perPage=${perPage}`,
      {
        withCredentials:true
      }
    );
    console.log(response.data);
    return response.data;
  },
  getOne: async (resourse, params) => {
    const id = params.id;
    const response = await axios.get(
      `${baseUrl}/${resourse}/${id}`,
      {}
    );
    console.log(response.data);
    return response.data;
  },
};
export const App = () => {
  return (
    <Admin dataProvider={daataProvider}
    history={history}
    loginPage={LoginPage}   
    authProvider={authProvider}
    >
      <Resource name="users" show={PostShow} list={PersonsList} />
    </Admin>
  );
};
export const PostShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <EmailField source="email" />

      <ArrayField source="pets" label={'Животные'}>
        <Datagrid>
          <NumberField source="id" />
          <TextField source="name"  label={'Имя'}/>
          <NumberField source="age" label={'Возраст'}/>
          <DateField source="registrationDate" label={'Дата регистрации'}/>
          <DateField source="dateOfBirth" label={'Дата рождения'}/>
          <TextField source="food" label={'Еда'}/>
          <TextField source="status" label={'Отношение'}/>
          <BooleanField source="sterilized" label={'Стреиализован'}/>
          <NumberField source="breed_id" label={'ID Породы'}/>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
