import axios from "axios";
import { promises } from "dns";

const obj = axios.create({ baseURL: process.env.REACT_APP_API_URL, withCredentials: true })

export default {
    // called when the user attempts to log in
    login: async ({ username, password }) => {
        let allow = false
        await obj.post("login", { "email": username, "password": password })
            .catch(() => Promise.reject())
        await obj.get("checkLogin")
            .then(res => {
                 allow = true
            })
        localStorage.setItem('username', username);
        if (allow) {
            return Promise.resolve()
        }
        else {           
            return Promise.reject()
        }
    },
    // called when the user clicks on the logout button
    logout: async () => {
       try{ await obj.get("logout")
            .then()
            return Promise.resolve();
        }catch(e){
            Promise.reject()
        }
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status >= 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        try {
            const response = await obj.get("checklogin")
            console.log(response)
        } catch (e) {
            return Promise.reject();
            console.log(e)
        }
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};