import axios from "axios";
import * as React from "react";
import { FC } from "react";
import { List, Datagrid, TextField, EmailField, BooleanField, ShowButton, ListButton, ExportButton, Button } from 'react-admin';





// const url = `${baseUrl}/xlsx?filename=${fileName}`

export const PersonsList: FC<{}> = (props) => {
    const url = makeUrl()
    return (

        <List {...props} actions={<Button href={url}
            //@ts-ignore
            download={"test.xlsx"} label="Export" />}>
            <Datagrid >
                <TextField source="id" />
                <TextField source="name" label={'Имя'} />
                <EmailField source="email" />
                <TextField source="registrationDate" label={'Дата регистрации'} />
                <TextField source="phone" label={'Телефон'} />
                {/* <BooleanField source="isConfirmationEmail" /> */}

                <PetsShowButton />
            </Datagrid>
        </List>
    )
};

const PetsShowButton = ({ record }: any) => (
    <ShowButton label="Pets" record={record} />
);

const getFileName = (): string => {
    const today = new Date();
    const timeString = today.toLocaleTimeString('ru-RU').replace(/:/g, '_')
    const dateString = `${today.getDate()}_${today.getMonth() + 1}_${today.getFullYear()}`
    return `perepis-hvostikov_${dateString}_${timeString}.xlsx`
}
const makeUrl = (): string => {
    const baseUrl = process.env.REACT_APP_API_URL || "https://perepis-hvostikov.ru"
    const fileName = getFileName()
    const url = `${baseUrl}/xlsx?filename=${fileName}`
    return url;
}
